import { For, Show } from 'solid-js';
import { Button, Separator } from '@troon/ui';
import { produce } from 'solid-js/store';
import { getConfigValue } from '../../modules/config';
import type { SearchPlaceFragment } from '../../graphql';
import type { SearchStore, SetSearchStore } from '../../modules/search-store';
import type { teeTimeSearchSchema } from '../tee-time-search';

type Props = {
	noResults?: boolean;
	filters: SearchStore<typeof teeTimeSearchSchema>;
	setFilters: SetSearchStore<typeof teeTimeSearchSchema>;
};

export function TeeTimeBlankData(props: Props) {
	return (
		<div class="mx-auto flex h-full w-fit flex-col items-center justify-center gap-8 py-12 text-center xl:py-36">
			<img src={`${getConfigValue('CDN')}/web/photo/icons/search.svg`} alt="" width={270} height={160} />
			<p class="mx-auto max-w-96">
				<Show
					when={props.noResults}
					fallback={
						<span class="font-semibold">Search for tee times at Troon affiliated courses by name or location.</span>
					}
				>
					There are no courses available near <b class="whitespace-nowrap">“{props.filters.query}”</b>. Update your
					search terms or try one of our popular locations.
				</Show>
			</p>
			<Separator class="w-full">Popular Destinations</Separator>
			<div class="flex flex-wrap justify-center gap-3">
				<For each={popularLocationsFallback}>
					{(location) => (
						<Button
							appearance="secondary-current"
							class="size-fit grow-0 bg-white text-neutral"
							onClick={() => {
								props.setFilters(
									produce((s) => {
										s.query = `${location.name}, ${location.regionName}`;
										s.lat = location.coordinates.latitude;
										s.lon = location.coordinates.longitude;
									}),
								);
							}}
						>
							<span class="normal-case text-brand">
								{location.name}, {location.regionName}
							</span>
						</Button>
					)}
				</For>
			</div>
		</div>
	);
}

const popularLocationsFallback: Array<SearchPlaceFragment> = [
	{
		countryName: 'USA',
		name: 'Scottsdale',
		regionName: 'AZ',
		coordinates: { latitude: 33.4956, longitude: -111.9182 },
	},
	{
		countryName: 'USA',
		name: 'Tucson',
		regionName: 'AZ',
		coordinates: { latitude: 32.2545, longitude: -110.984 },
	},
	{
		countryName: 'USA',
		name: 'Palm Springs',
		regionName: 'CA',
		coordinates: { latitude: 33.8312, longitude: -116.5414 },
	},
	{
		countryName: 'USA',
		name: 'Naples',
		regionName: 'Fl',
		coordinates: { latitude: 26.14212, longitude: -81.79419 },
	},
];
